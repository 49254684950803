.toastui-editor-mode-switch {
  display: none !important;
}

.toastui-editor-contents {
  background-color: #fff;
}

.toastui-editor-contents p {
  color: #000;
}
.toastui-editor-contents p > img {
  width: 100%;
  height: auto;
}

.toastui-editor-contents h1,
.toastui-editor-contents h2,
.toastui-editor-contents h3,
.toastui-editor-contents h4,
.toastui-editor-contents h5,
.toastui-editor-contents h6 {
  font-family: var(--font-family);
  color: #000;
  border-bottom: 0;
  line-height: normal;
}

.toastui-editor-popup-body > ul > li:nth-child(2),
.toastui-editor-popup-body > ul > li:nth-child(3),
.toastui-editor-popup-body > ul > li:nth-child(4),
/* .toastui-editor-popup-body > ul > li:nth-child(5), */
.toastui-editor-popup-body > ul > li:nth-child(6) {
  display: none;
}

/** font-size **/

/* H7 */
.toastui-editor-md-heading1,
.toastui-editor-contents h1 {
  padding: 0;
  font-family: var(--font-family);
  margin: 0 0 12px 0;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.15px;
}

/* Body1 */
.toastui-editor-md-heading2,
.toastui-editor-md-heading3,
.toastui-editor-md-heading4,
.toastui-editor-md-heading5,
.toastui-editor-md-heading6,
.toastui-editor-contents h2,
.toastui-editor-contents h3,
.toastui-editor-contents h4,
.toastui-editor-contents h6,
.toastui-editor-contents p {
  padding: 0;
  font-family: var(--font-family);
  margin: 0 0 0 0;
  line-height: 1.5;
  font-weight: 400;
  font-size: 15px;
}

.toastui-editor-contents h5 {
  padding: 0;
  font-family: var(--font-family);
  margin: 0 0;
  line-height: 1.5;
  font-weight: 400;
  font-size: 15px;
}

.toastui-editor-md-block-quote,
.toastui-editor-contents blockquote {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 15px;
  box-sizing: border-box !important;
  min-height: 20px;
  margin: 32px 0;
  padding: 4px 16px 4px 12px;
  color: #000;
}
