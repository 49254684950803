:root {
  --contents-witdh: 1200px;
  --font-family: "SUIT";
}

* {
  box-sizing: border-box;
  text-size-adjust: none;
}
::selection {
  background-color: rgba(242, 234, 246, 0.7);
  /* color: #000000; */
}

html {
  font-family: var(--font-family);
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
  opacity: 0.8;
}

#__next::-webkit-scrollbar {
  display: none;
}

.image-lazy-load-child-full > img {
  width: 100%;
  height: 100%;
}

hr {
  margin: 0;
  height: 0.5px;
  background-color: #707070;
  opacity: 0.5;
  border-width: 0;
}

input,
textarea,
button {
  font-family: "SUIT";
  color: black;
}

/* 모달창 스크롤 바 없애는 전역 스타일 */
.MODAL::-webkit-scrollbar {
  display: none;
}

.MODAL:focus {
  outline: none !important;
}

/* react-infinite-scroll-component라이브러리 전역 스타일 */
.INFINITESCROLL {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.INFINITESCROLL::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
